import axios from "axios";

let URI = "https://api.service64.com/app";
// let URI = "http://localhost:9000/app";

export const selectCountry = (data) => ({
  type: "selecedtCountry",
  payload: data,
});

export const search_res = (data) => ({
  type: "search_res",
  payload: data,
});

export const upload_img = (data) => ({
  type: "upload_img",
  payload: data,
});

export const val = (data) => ({
  type: "val",
  payload: data,
});

export const ope_hrs = (data) => ({
  type: "ope_hrs",
  payload: data,
});

export const get_amenities = (data) => ({
  type: "get_amenities",
  payload: data,
});

export const listing_details = (data) => ({
  type: "listing_details",
  payload: data,
});

export const moveto = (data) => ({
  type: "moveto",
  payload: data,
});

// <<<<<<<<<<<<< Authentication >>>>>>>>>>>>>>>>>>
// <<<<<<<<<<<<< Authentication >>>>>>>>>>>>>>>>>>
// <<<<<<<<<<<<< Authentication >>>>>>>>>>>>>>>>>>

export function user_signup(body) {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      axios
        .post(`${URI}/auth/signup`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function user_signin(body) {
  console.log(body);
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      axios
        .post(`${URI}/auth/signin`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

// <<<<<<<<<<<<< Searching >>>>>>>>>>>>>>>>>>
// <<<<<<<<<<<<< Searching >>>>>>>>>>>>>>>>>>
// <<<<<<<<<<<<< Searching >>>>>>>>>>>>>>>>>>

export function search(body) {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      axios
        .post(`${URI}/search`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function searchByCountry(body) {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      axios
        .post(`${URI}/searchbycountry`, body)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function searchByCity(body) {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      axios
        .post(`${URI}/searchbycity`, body)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function searchByLocation(body) {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      axios
        .post(`${URI}/searchbylocation`, body)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function searchByCategory(body) {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      axios
        .post(`${URI}/searchbycategory`, body)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

//`${URI}///
export function recommendedCityCategory(body) {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      axios
        .post(`${URI}/recommendedcategory`, body)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

// <<<<<<<<<<<<< listing >>>>>>>>>>>>>>>>>>
// <<<<<<<<<<<<< listing >>>>>>>>>>>>>>>>>>
// <<<<<<<<<<<<< listing >>>>>>>>>>>>>>>>>>
// https://service64.herokuapp.com/
// https://dirto.herokuapp.com

export function add_listing(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/listing/add_listing`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
// https://dirto.herokuapp.com

export function get_listing(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/listing/get_listing`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function get_listing_usr(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/listing/get_listing/usr`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function del_listing(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/listing/del_listing`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

// <<<<<<<<<<<<< listing >>>>>>>>>>>>>>>>>>
// <<<<<<<<<<<<< listing >>>>>>>>>>>>>>>>>>
// <<<<<<<<<<<<< listing >>>>>>>>>>>>>>>>>>
// https://service64.herokuapp.com/
// https://dirto.herokuapp.com

// get_unapproved_listing

export function get_unapproved_listing(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/get_unapproved_listing`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function get_approved_listing(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/get_unapproved_listing`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function approved_listing(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/apr_listing`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function unapproved_listing(body) {
  console.log(body);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/unapr_listing`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function admin_delete_listing(body) {
  console.log(body);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/admin_delete_listing`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function add_cat(body) {
  console.log(body);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/add_cat`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function get_cat() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URI}/admin/get_cat`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function del_cat(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/del_cat`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function edt_cat(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/edt_cat`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function add_cit(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/add_loc`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function get_cit() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URI}/admin/get_cit`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function del_cit(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/del_cit`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function edt_cit(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/edt_cit`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function add_loc(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/add_loc`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function get_loc() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URI}/admin/get_loc`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function del_loc(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/del_loc`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function edt_loc(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/edt_loc`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function mailtoadmin(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/mail/mailtoadmin`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function get_rep() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URI}/admin/get_rep`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function del_rev(body) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${URI}/admin/del_rev`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function res_rev(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URI}/admin/res_rev/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
