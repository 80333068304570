import React, { Component } from 'react';
import { AiOutlineUser } from 'react-icons/ai'
import { FiLock } from 'react-icons/fi'
import { Link } from "react-router-dom";



class LoginBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            //////////////////////////////////////////////
            //////////////////////////////////////////////
            ////////////////////////////////////////////// 
            //You can change your email and password here //
            //You can change your email and password here //
            adminEmail: 'admin@service64.com',
            adminPassword: '#g7U63N4&&',

            //////////////////////////////////////////////
            //////////////////////////////////////////////
            //////////////////////////////////////////////

            email: '',
            password: ''
        }
    }

    value(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    signin() {
        const { password, email, adminPassword, adminEmail } = this.state
        if (email !== adminEmail) {
            this.setState({
                message_err: 'Please enter valide email  !',
            })
        }
        else if (password !== adminPassword) {
            this.setState({
                message_err: 'Your password must be at least 8 characters long !',
            })
        }

        
        else {
            const admin = {
                email, password
            }
            localStorage.setItem('__Admin__', JSON.stringify(admin))
            this.props.history.push('/admin')
        }
    }

    render() {
        return (
            <>
                <div className="billing-form-item mb-0">
                    <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
                        <h3 className="widget-title font-size-28 pb-0">
                            {this.props.title}
                        </h3>
                        <p className="font-size-16 font-weight-medium">
                            {this.props.subtitle}
                        </p>
                    </div>
                    <div className="billing-content">
                        <div className="contact-form-action">
                            {/* <form method="post"> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <label className="label-text">Email Adresse</label>
                                        <div className="form-group">
                                            <span className="form-icon">
                                                <AiOutlineUser />
                                            </span>
                                            <input value={this.state.email} name='email' onChange={this.value.bind(this)} className="form-control" type="text" placeholder="email, or phone number" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <label className="label-text">Password</label>
                                        <div className="form-group">
                                            <span className="form-icon">
                                                <FiLock />
                                            </span>
                                            <input value={this.state.password} name='password' onChange={this.value.bind(this)} className="form-control" type="password" placeholder="Password" />
                                        </div>
                                    </div>
                                </div>
                                {/*
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        {this.state.message_err && <div className="alert alert-danger" >{this.state.message_err}</div>}
                                        <div className="custom-checkbox mr-0 d-flex align-items-center justify-content-between">
                                            <div>
                                                <input type="checkbox" id="chb1" />
                                                <label htmlFor="chb1">Remember Me</label>
                                            </div>

                                            <div>
                                                <Link to="/recover" className="color-text font-weight-medium">
                                                    Forgot password?
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                */}

                                <div className="col-lg-12">
                                    <div className="btn-box margin-top-20px margin-bottom-20px">
                                        <button className="theme-btn border-0" type="button" onClick={this.signin.bind(this)} >
                                            Login now
                                        </button>
                                    </div>
                                </div>

                                {/*
                                <div className="col-lg-12">
                                    <p className="font-weight-medium">Not a member? <Link to="/sign-up" className="color-text"> Register</Link></p>
                                </div>
                                */}
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}



export default LoginBox