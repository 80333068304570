import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  get_unapproved_listing,
  admin_delete_listing,
  approved_listing,
  get_approved_listing,
  unapproved_listing,
  add_cat,
} from "../../store/action";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { FcDisapprove, FcApprove } from "react-icons/fc";
// import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Categories from "./AddCategories";
import AddCities from "./AddCities";
import AddLocations from "./AddLocations";
import ReviewReports from "./ReviewReports";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { storage } from "./Storage";

import Pagination from "react-js-pagination";
import { ArrowIcon } from "../../assets/svg";

class Addmin_panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access: false,
      is_logedin: true,
      loading: true,
      unapprv: [],
      approv: [],
      pageNumber: 1,
      //approved listing show limit//
      perPageRecords: 150,
      cards: [
        {
          img: require("../../assets/images/img25.jpg"),
          title: "Favorite Place Food Bank",
          subtitle: "Bishop Avenue, New York",
          editTxt: "Edit",
          editIcon: <FaRegEdit />,
          deleteTxt: "Delete",
          deleteIcon: <FaRegTrashAlt />,
          cardLink: "/listing-details",
        },
        {
          img: require("../../assets/images/img26.jpg"),
          title: "Beach Blue Boardwalk",
          subtitle: "Bishop Avenue, New York",
          editTxt: "Edit",
          editIcon: <FaRegEdit />,
          deleteTxt: "Delete",
          deleteIcon: <FaRegTrashAlt />,
          cardLink: "/listing-details",
        },
        {
          img: require("../../assets/images/img27.jpg"),
          title: "Hotel Govendor",
          subtitle: "Bishop Avenue, New York",
          editTxt: "Edit",
          editIcon: <FaRegEdit />,
          deleteTxt: "Delete",
          deleteIcon: <FaRegTrashAlt />,
          cardLink: "/listing-details",
        },
        {
          img: require("../../assets/images/img28.jpg"),
          title: "Favorite Place Food Bank",
          subtitle: "Bishop Avenue, New York",
          editTxt: "Edit",
          editIcon: <FaRegEdit />,
          deleteTxt: "Delete",
          deleteIcon: <FaRegTrashAlt />,
          cardLink: "/listing-details",
        },
        {
          img: require("../../assets/images/img29.jpg"),
          title: "Beach Blue Boardwalk",
          subtitle: "Bishop Avenue, New York",
          editTxt: "Edit",
          editIcon: <FaRegEdit />,
          deleteTxt: "Delete",
          deleteIcon: <FaRegTrashAlt />,
          cardLink: "/listing-details",
        },
        {
          img: require("../../assets/images/img30.jpg"),
          title: "Hotel Govendor",
          subtitle: "Bishop Avenue, New York",
          editTxt: "Edit",
          editIcon: <FaRegEdit />,
          deleteTxt: "Delete",
          deleteIcon: <FaRegTrashAlt />,
          cardLink: "/listing-details",
        },
      ],
      appr: "Approve",
      paus: "Pause",
      emtpty_apr_listin: false,
      emtpty_unp_listin: false,
    };
  }

  get_listing() {
    const { approv, unapprv } = this.state;
    let app_arr = [];
    let unp_arr = [];
    this.props.actions.get_unapproved_listing().then((res) => {
      this.setState({ loading: false });
      let listing = res.data;
      if (listing.length > 0) {
        for (var k in listing) {
          if (listing[k].approved !== true) {
            unp_arr.push(listing[k]);
            this.setState({
              unapprv: unp_arr,
            });
          } else {
            app_arr.push(listing[k]);
            this.setState({
              approv: app_arr,
            });
          }
        }
      } else {
      }
    });
  }

  numberShow() {
    const { number } = this.state;
    let num = number;
    const details = this.props.item;
    if (details !== undefined) {
      num = details.user_number;
      let changeStyle = {
        transition: "2s",
        color: "black",
      };
      this.setState({
        styles: changeStyle,
        hid: details.user_number.slice(4),
      });
    } else {
      let changeStyle = {
        transition: "2s",
        color: "black",
      };
      this.setState({
        styles: changeStyle,
        hid: num.slice(4),
      });
    }
  }

  componentDidMount() {
    const current_user = JSON.parse(localStorage.getItem("__Admin__"));
    if (current_user !== null) {
      this.get_listing();
      this.setState({
        access: true,
        user_id: current_user._id,
        // loading: false,
        is_logedin: true,
      });
    } else {
      this.props.history.push("/");
    }
  }

  get_del_id_by_apr(item, i) {
    console.log(item.photo_name);

    const { approv } = this.state;
    approv.splice(i, 1);
    this.setState({
      approv: approv,
    });
    this.props.actions
      .admin_delete_listing({ _id: item._id })
      .then((response) => {
        const desertRef = storage.ref().child(`/images/${item.photo_name}`);
        desertRef
          .delete()
          .then(function () {
            console.log("file deleted");
          })
          .catch(function (error) {
            console.log(error);
          });
        this.get_listing();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  get_del_id_by_unp(item, i) {
    const { unapprv } = this.state;
    console.log(item);
    unapprv.splice(i, 1);
    this.setState({
      unapprv: unapprv,
    });
    this.props.actions
      .admin_delete_listing({ _id: item._id })
      .then((response) => {
        const desertRef = storage.ref().child(`/images/${item.photo_name}`);
        desertRef
          .delete()
          .then(function () {
            console.log("file deleted");
          })
          .catch(function (error) {
            console.log(error);
          });
        this.get_listing();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  approval(_id, i) {
    const { approv, unapprv } = this.state;
    unapprv.splice(i, 1);
    this.setState({
      unapprv: unapprv,
    });
    this.props.actions
      .approved_listing({ _id: _id })
      .then((response) => {
        this.get_listing();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  unapproval(_id, i) {
    const { approv, unapprv } = this.state;
    approv.splice(i, 1);
    this.setState({
      approv: approv,
    });
    this.props.actions
      .unapproved_listing({ _id: _id })
      .then((response) => {
        this.get_listing();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  previousPage = () => {
    if (this.state.pageNumber !== 1)
      this.setState({ pageNumber: this.state.pageNumber - 1 });
  };

  nextPage = () => {
    if (
      this.state.pageNumber <=
      Math.floor(this.state.approv.length / this.state.perPageRecords)
    )
      this.setState({ pageNumber: this.state.pageNumber + 1 });
  };

  render() {
    const { cards, loading, unapprv, approv, appr, paus } = this.state;
    return (
      <>
        <nav className="navbar generic-header navbar-expand-lg  navbar-dark bg-dark ">
          <a className="navbar-brand" href="#">
            Service64 Admin
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse center"
            id="navbarNavDropdown"
          >
            
          </div>

          <form className="form-inline my-2 my-lg-0">
            <button className="btn btn-success my-2 my-sm-0" type="submit">
              Save changes
            </button>
          </form>
        </nav>
        <>
          <section className="dashboard-area padding-top-40px padding-bottom-90px">
            <div className="container">
              <Tabs>
                <div className="col-lg-12">
                  <div className="dashboard-nav d-flex justify-content-between align-items-center mb-4">
                    <TabList className="nav nav-tabs border-0" id="nav-tab">
                      <Tab>
                        <Link
                          className="nav-item nav-link theme-btn pt-0 pb-0 mr-1"
                          to="#"
                        >
                          <span className="la"></span> Approved Listings
                        </Link>
                      </Tab>
                      <Tab>
                        <Link
                          className="nav-item nav-link theme-btn pt-0 pb-0 mr-1"
                          to="#"
                        >
                          <span className="la"></span> Unapproved Listings
                        </Link>
                      </Tab>
                      <Tab>
                        <Link
                          className="nav-item nav-link theme-btn pt-0 pb-0 mr-1"
                          to="#"
                        >
                          <span className="la"></span> Categories
                        </Link>
                      </Tab>

                      <Tab>
                        <Link
                          className="nav-item nav-link theme-btn pt-0 pb-0 mr-1"
                          to="#"
                        >
                          <span className="la"></span> Cities
                        </Link>
                      </Tab>

                      <Tab>
                        <Link
                          className="nav-item nav-link theme-btn pt-0 pb-0 mr-1"
                          to="#"
                        >
                          <span className="la"></span> Locations
                        </Link>
                      </Tab>
                      <Tab>
                        <Link
                          className="nav-item nav-link theme-btn pt-0 pb-0 mr-1"
                          to="#"
                        >
                          <span className="la"></span> Review Reports
                        </Link>
                      </Tab>
                    </TabList>
                  </div>
                  <TabPanel>
                    {loading ? (
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <span>
                          <Spinner animation="grow" id="loder" />
                        </span>
                      </div>
                    ) : (
                      <div className="row">
                        {approv.length < 1 ? (
                          <div
                            style={{
                              textAlign: "center",
                              width: "100%",
                              margin: "50px 0px",
                            }}
                          >
                            <h2>No any listing here !</h2>{" "}
                          </div>
                        ) : (
                          <>
                            {approv
                              .filter(
                                (row, index) =>
                                  index >=
                                    (this.state.pageNumber - 1) *
                                      this.state.perPageRecords &&
                                  index <=
                                    (this.state.pageNumber - 1) *
                                      this.state.perPageRecords +
                                      (this.state.perPageRecords - 1)
                              )
                              .map((item, i) => {
                                console.log(item.locations);
                                return (
                                  <div
                                    key={i}
                                    className="col-lg-4 column-td-6 admin-card-item"
                                  >
                                    <div className="">
                                      <div className="col-lg-4 float-left">
                                        <div className="admin-card-image">
                                          <img
                                            src={item.seller_img}
                                            className=""
                                            alt="Card"
                                          />
                                        </div>
                                      </div>
                                      <div className="admin-card-content col-lg-8">
                                        <div className="card-content">
                                          <div>
                                            <h4 className="admin-title admin-mt-0">
                                              {item.fullname}
                                            </h4>
                                            <p className="admin-card-sub">
                                              {"Phone: " + item.user_number}
                                            </p>
                                            {item.locations.length > 1 && (
                                              <p className="admin-card-location">
                                                {item.city +
                                                  ", " +
                                                  item.locations[0] +
                                                  " (" +
                                                  (item.locations.length - 1) +
                                                  " more) "}
                                              </p>
                                            )}
                                            {item.locations.length < 2 && (
                                              <p className="admin-card-location">
                                                {item.city +
                                                  ", " +
                                                  item.locations[0]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                        <div className="">
                                          <div className="edit-info-box">
                                            {item.approved ? (
                                              <button
                                                type="button"
                                                className="admin-card-button border-0 mr-1"
                                                onClick={this.unapproval.bind(
                                                  this,
                                                  item._id,
                                                  i
                                                )}
                                              >
                                                <span className="la">
                                                  <FcDisapprove />
                                                </span>{" "}
                                                {paus}
                                              </button>
                                            ) : (
                                              <button
                                                type="button"
                                                className="admin-card-button border-0 mr-1"
                                                onClick={this.approval.bind(
                                                  this,
                                                  item._id,
                                                  i
                                                )}
                                              >
                                                <span className="la">
                                                  <FcApprove />
                                                </span>{" "}
                                                {appr}
                                              </button>
                                            )}
                                            <button
                                              type="button"
                                              className="admin-card-button-delete border-0"
                                              onClick={this.get_del_id_by_apr.bind(
                                                this,
                                                item,
                                                i,
                                                i
                                              )}
                                            >
                                              <span className="la">
                                                {cards[0].deleteIcon}
                                              </span>{" "}
                                              {cards[0].deleteTxt}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </div>
                    )}

                    <div className="paging">
                      {this.state.approv.length > 0 ? (
                        <Pagination
                          hideFirstLastPages={true}
                          prevPageText={
                            <span
                              className="previous-page"
                              onClick={this.previousPage}
                            >
                              <ArrowIcon />
                              Previous
                            </span>
                          }
                          nextPageText={
                            <span className="next-page" onClick={this.nextPage}>
                              Next
                              <ArrowIcon />
                            </span>
                          }
                          activePage={this.state.pageNumber}
                          itemsCountPerPage={this.state.perPageRecords}
                          totalItemsCount={this.state.approv.length - 1}
                          pageRangeDisplayed={50}
                          onChange={(page) => {
                            this.setState({
                              pageNumber: page,
                            });
                          }}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    {loading ? (
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <span>
                          <Spinner animation="grow" id="loder" />
                        </span>
                      </div>
                    ) : (
                      <div className="row">
                        {unapprv.length < 1 ? (
                          <div
                            style={{
                              textAlign: "center",
                              width: "100%",
                              margin: "50px 0px",
                            }}
                          >
                            <h2>We don't have any unapproved listing.</h2>
                          </div>
                        ) : (
                          <>
                            {unapprv.map((item, i) => {
                              return (
                                
                                <div key={i} className="col-lg-4 column-td-6">
                                  <div className="card-item">
                                    <div className="card-image-wrap">
                                      <div
                                        className="card-image"
                                        style={{
                                          height: "250px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <img
                                          src={item.seller_img}
                                          className="card__img"
                                          alt="Card"
                                        />
                                      </div>
                                    </div>
                                    <div className="card-content-wrap">
                                      <div className="card-content">
                                        <div className="unapproved-card-content">
                                          <h4 className="card-title mt-0">
                                            {item.fullname}
                                          </h4>
                                          <p className="card-sub">
                                            {"City: " +
                                              item.city}
                                          </p>
                                          <p style={{
                                            color:"#333"
                                          }} className="card-sub">
                                            {"Number: " +
                                              item.user_number}
                                          </p>
                                        </div>
                                      </div>
                                      
                                      <div className="unApprovedCard">
                                        <p> <b>Working Area:</b> {item.locations + ", "}</p>
                                        <p> <b>Working Category:</b> {item.category}</p>
                                        <p> <b>Date of birth:</b> {item.date_of_birth} </p>
                                        <p> <b>NID:</b> {item.national_id}</p>
                                        <p> <b>Present A:</b> {item.current_address}</p>
                                        <p> <b>Permanent A:</b>  {item.permanent_address}</p>
                                        <p> <b>Description:</b> {item.description}</p>                                        
                                      </div>


                                      <div className="rating-row">
                                        <div className="edit-info-box">
                                          {item.approved ? (
                                            <button
                                              type="button"
                                              className="theme-btn button-success border-0 mr-1"
                                              onClick={this.unapproval.bind(
                                                this,
                                                item._id,
                                                i
                                              )}
                                            >
                                              <span className="la">
                                                <FcDisapprove />
                                              </span>{" "}
                                              {paus}
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              className="theme-btn button-success border-0 mr-1"
                                              onClick={this.approval.bind(
                                                this,
                                                item._id,
                                                i
                                              )}
                                            >
                                              <span className="la">
                                                <FcApprove />
                                              </span>{" "}
                                              {appr}
                                            </button>
                                          )}
                                          <button
                                            type="button"
                                            className="theme-btn delete-btn border-0"
                                            onClick={this.get_del_id_by_unp.bind(
                                              this,
                                              item,
                                              i
                                            )}
                                          >
                                            <span className="la">
                                              {cards[0].deleteIcon}
                                            </span>{" "}
                                            {cards[0].deleteTxt}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {loading ? (
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <span>
                          <Spinner animation="grow" id="loder" />
                        </span>
                      </div>
                    ) : (
                      <Categories />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {loading ? (
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <span>
                          <Spinner animation="grow" id="loder" />
                        </span>
                      </div>
                    ) : (
                      <AddCities />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {loading ? (
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <span>
                          <Spinner animation="grow" id="loder" />
                        </span>
                      </div>
                    ) : (
                      <AddLocations />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {loading ? (
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <span>
                          <Spinner animation="grow" id="loder" />
                        </span>
                      </div>
                    ) : (
                      <ReviewReports />
                    )}
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </section>
        </>
      </>
    );
  }
}

const mapDispatchToProps = (dispatchEvent) => {
  return {
    actions: bindActionCreators(
      {
        get_unapproved_listing,
        admin_delete_listing,
        approved_listing,
        unapproved_listing,
        get_approved_listing,
        add_cat,
      },
      dispatchEvent
    ),
  };
};

const mapStateToProps = (state) => {
  return {
    item: state.search_res,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Addmin_panel);
