import React, { Component } from "react";
import { Switch, Route } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./App.css";
import Admin_panel from "./pages/admin/Adminpanel";
import Login from "./pages/Login";

class App extends Component {
  render() {
    return (
      <div>
        <Route>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/admin" component={Admin_panel} />
          </Switch>
        </Route>
      </div>
    );
  }
}

export default App;
