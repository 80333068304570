import React, { Component } from 'react';
import LoginBox from "../components/other/account/LoginBox";


class Login extends Component {
    state = {
        breadcrumbimg: require('../assets/images/highway.jpg'),
    }
    render() {
        return (
            <main className="login-page">
                <section className="form-shared padding-top-40px padding-bottom-100px">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mx-auto">
                                <LoginBox title="Login to admin panel" history={this.props.history}  />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default Login;