import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { get_rep, res_rev, del_rev } from "../../store/action";
import { IoMdStar } from "react-icons/io";
import logo from "../../assets/images/dots.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class ReviewReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      index: "",
      reports: [],
      message_err: "",
      loading: false,
    };
    this.get_rep = this.get_rep.bind(this);
    this.resolveReview = this.resolveReview.bind(this);
    this.deleteReview = this.deleteReview.bind(this);
  }

  get_rep() {
    this.props.actions.get_rep().then((res) => {
      this.setState({
        reports: res.data,
      });
    });
  }

  resolveReview = (reportId) => {
    this.props.actions.res_rev(reportId).then((res) => {
      this.setState((prev) => {
        return {
          ...prev,
          reports: this.state.reports.filter(
            (report) => report._id !== reportId
          ),
        };
      });
    });
  };

  deleteReview = (reportId, userId, reviewId) => {
    this.props.actions.del_rev({ reportId, userId, reviewId }).then((res) => {
      if (res.data.message == "SUCCESS") {
        this.setState((prev) => {
          return {
            ...prev,
            reports: this.state.reports.filter(
              (report) => report._id !== reportId
            ),
          };
        });
      }
      // this.setState({
      //   reports: res.data,
      // });
    });
  };

  componentDidMount() {
    this.get_rep();
  }

  render() {
    let { loading } = this.state;
    let reportsArray = this.state.reports.map((report) => (
      <UserReport
        key={report._id}
        report={report}
        deleteReview={this.deleteReview}
        resolveReview={this.resolveReview}
      />
    ));

    return (
      <>
        {loading ? (
          <div style={{ textAlign: "center", width: "100%" }}>
            <span>
              <Spinner animation="grow" id="loder" />
            </span>
          </div>
        ) : (
          reportsArray
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatchEvent) => {
  return {
    actions: bindActionCreators(
      {
        get_rep,
        res_rev,
        del_rev,
      },
      dispatchEvent
    ),
  };
};

const mapStateToProps = (state) => {
  return {
    item: state.search_res,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewReports);

function UserReport({ deleteReview, resolveReview, report }) {
  let { review } = report;
  return (
    <div className="row col-lg-12 col-md-12 user-review-container">
      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-3 center-col review-img-container">
        <img
          src={!!report.image_uri ? report.image_uri : logo}
          className="img-rounded review-img"
        />
      </div>
      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-9">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div className="review-block-name">
              <a href="#">{report.user_name}</a>
            </div>
          </div>
          <div>
            <button
              className="btn btn-outline-danger m-1"
              style={{ height: "50%" }}
              onClick={() =>
                deleteReview(report._id, report.user_id, report.review_id)
              }
            >
              Delete
            </button>
            <button
              className="btn btn-outline-info m-1"
              style={{ height: "50%" }}
              onClick={() => resolveReview(report._id)}
            >
              Resolve
            </button>
          </div>
        </div>
        <div className="review-block-description">{report.description}</div>
      </div>

      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2 center-col"></div>

      <div className="row col-lg-11 col-md-11 col-sm-11 col-xs-10 my-2 p-0">
        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-3 center-col review-img-container">
          <img
            src={!!review.image_uri ? review.image_uri : logo}
            className="img-rounded review-img"
          />
        </div>
        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-9">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="row col-lg-12 col-md-12 col-sm-12 p-0">
              <div className="review-block-name p-1">
                <a href="#">{review.reviewer_name}</a>
              </div>
              {/* <div className="review-block-date">{date.current}</div> */}
              <div className="row col-lg-12 col-md-12 col-sm-12">
                <div className="review-block-rate p-1 col-lg-3 col-md-3 col-sm-6">
                  <h6>Communication</h6>
                  <IoMdStar
                    style={{ pointerEvents: "none" }}
                    size={25}
                    color="#007bff"
                    //   color={color}
                  />
                  ({review.communication * 0.05})
                </div>
                <div className="review-block-rate p-1 col-lg-3 col-md-3 col-sm-6">
                  <h6>Recommendation</h6>
                  <IoMdStar
                    style={{ pointerEvents: "none" }}
                    size={25}
                    color="#007bff"
                    //   color={color}
                  />
                  ({review.recommendation * 0.05})
                </div>
                <div className="review-block-rate p-1 col-lg-3 col-md-3 col-sm-6">
                  <h6>Service</h6>
                  <IoMdStar
                    style={{ pointerEvents: "none" }}
                    size={25}
                    color="#007bff"
                    //   color={color}
                  />
                  ({review.expertise * 0.05})
                </div>
                <div className="review-block-rate p-1 col-lg-3 col-md-3 col-sm-6">
                  <h6>Behaviour</h6>
                  <IoMdStar
                    style={{ pointerEvents: "none" }}
                    size={25}
                    color="#007bff"
                    //   color={color}
                  />
                  ({review.behaviour * 0.05})
                </div>
              </div>
            </div>
          </div>
          <div className="review-block-description">{review.description}</div>
        </div>
      </div>

      {/* {true && (
        <div className="alert alert-danger alert-dismissible fade show form-group col-lg-12 col-md-12 m-2">
          <strong>Error!</strong> {"${error}"}
          <button type="button" className="close" data-dismiss="alert">
            &times;
          </button>
        </div>
      )} */}
    </div>
  );
}
